$enable-negative-margins: true;

@import "bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";

.rounded-9 {
  border-radius: 1rem;
}

.site-container {
  @extend .container-md;
  @extend .px-3;
  max-width: 740px;
}

header h1 a {
  $my-color: #6f42c1;

  padding: .3rem 1rem;
  margin: -.3rem -.5rem;
  text-decoration: none;

  transition: all .2s ease-in;

  color: #222;

  &:hover {
    @extend .shadow-lg;

    background: $my-color;
    color: #fff;

    transform: scale(1.02);
  }
}

.block {
  position: relative;
  transition: all .2s ease-in;
  transform: translate3d();

  z-index: 10;

  &:hover {
    @extend .shadow;
    transform: scale(1.01);
  }
}

.block--work {
  $tablo-color: #fc1159;

  a {
    color: $tablo-color;
  }

  &:hover {
    background-color: $tablo-color !important;
    
    &, a, p {
      color: #fff;
    }

    .text-muted {
      color: lighten($tablo-color, 33%) !important;
    }
  }
}

.block--twitter {
  $twitter-color: #1DA1F2;

  a {
    color: $twitter-color;
  }

  &:hover {
    background-color: $twitter-color !important;
    
    &, a, p {
      color: #fff;
    }

    .text-muted {
      color: lighten($twitter-color, 20%) !important;
    }
  }
}

.block--unsplash {
  $unsplash-color: #333;

  a {
    color: $unsplash-color;
  }

  &:hover {
    background-color: $unsplash-color !important;
    
    &, a, p {
      color: #fff;
    }

    .text-muted {
      color: lighten($unsplash-color, 40%) !important;
    }
  }
}